<template>
    <v-container class="contact-form pa-5" id="contact" justify="center">
        <h1 class="text-h4">Contact us</h1>
        <v-row v-if="!submitted" justify="center">
            <v-col cols="12" md="8" lg="6">
                <form @submit.prevent="submitForm" ref="contactForm">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Name"
                                name="first_name"
                                v-model="form.name"
                                outlined
                                required
                                :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Surname"
                                name="last_name"
                                v-model="form.surname"
                                outlined
                                required
                                :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Email"
                                name="email"
                                type="email"
                                v-model="form.email"
                                outlined
                                required
                                :rules="[rules.required, rules.email]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Mobile (Optional)"
                                name="mobile"
                                type="tel"
                                v-model="form.mobile"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                label="Message"
                                name="message"
                                v-model="form.message"
                                outlined
                                required
                                :rules="[rules.required]"
                                rows="5"
                                auto-grow
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row class="mt-4" justify="center">
                        <v-col cols="12" class="text-center">
                            <v-btn type="submit" color="#05c3a8" class="text-white">Submit</v-btn>
                        </v-col>
                    </v-row>
                </form>
            </v-col>
        </v-row>
        <div v-else>
            <v-row justify="center">
                <v-col cols="12" md="8" lg="6" class="text-center">
                    <v-alert type="success" style="background-color: #05c3a8; color: #19191e">
                        Thank you for your submission, we will get in touch with you soon!
                    </v-alert>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>
<script>
import axios from 'axios';

export default {
    name: 'Contact',
    data() {
        return {
            form: {
                name: '',
                surname: '',
                email: '',
                mobile: '',
                message: '',
            },
            submitted: false,
            rules: {
                required: (value) => !!value || 'Required.',
                email: (value) => {
                    const pattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
                    return pattern.test(value) || 'Invalid e-mail.';
                },
            },
        };
    },
    methods: {
        async submitForm() {
            try {
                const response = await axios.post(
                    'https://api.web3forms.com/submit',
                    {
                        access_key: '54a4e246-2cd0-466b-b699-4406948fe918',
                        first_name: this.form.name,
                        last_name: this.form.surname,
                        email: this.form.email,
                        mobile: this.form.mobile,
                        message: this.form.message,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                        },
                    },
                );

                if (response.data.success) {
                    this.submitted = true;
                }
            } catch (error) {
                console.error('There was an error submitting the form:', error);
            }
        },
    },
};
</script>
<style scoped>
.contact-form {
    background-color: #fff;
    color: #19191e;
    padding: 20px;
    border-radius: 8px;
    margin-top: 100px;
    margin-bottom: 100px;
    text-align: center;
}

.contact-form h1 {
    margin-bottom: 30px;
}

.contact-form .v-text-field,
.contact-form .v-textarea {
    margin-bottom: 20px;
}

.contact-form .v-btn {
    background-color: #05c3a8;
    color: white;
    width: 100%;
}

@media (max-width: 767px) {
    .contact-form {
        padding: 10px;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .contact-form h1 {
        font-size: 24px;
        margin-bottom: 20px;
    }
    .contact-form .v-text-field,
    .contact-form .v-textarea {
        margin-bottom: 15px;
    }
    .contact-form .v-btn {
        font-size: 14px;
        width: 100%;
    }
}
</style>
