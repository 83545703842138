<template>
    <v-container id="pricing" class="pa-5 pricing-container text-center">
        <h1 class="text-h4">Pricing</h1>
        <v-row justify="center" class="text-center">
            <v-col cols="12" md="8" lg="6">
                <v-card variant="flat" class="pa-3">
                    <div v-if="!submitted">
                        <v-form ref="form" v-model="valid">
                            <div v-if="step === 1">
                                <v-row>
                                    <v-col cols="12">
                                        <v-subheader>Tell us what you need!</v-subheader>
                                        <v-radio-group
                                            v-model="selectedCategory"
                                            @change="handleCategoryChange"
                                            column
                                            :rules="[rules.required]"
                                        >
                                            <v-radio
                                                label="Website"
                                                value="Website"
                                                color="#05c3a8"
                                            ></v-radio>
                                            <v-radio
                                                label="Online Store"
                                                value="OnlineStore"
                                                color="#05c3a8"
                                            ></v-radio>
                                            <v-radio
                                                label="Portfolio"
                                                value="Portfolio"
                                                color="#05c3a8"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </div>

                            <div v-if="step === 2">
                                <v-btn
                                    @click="goBack"
                                    color="#05c3a8"
                                    outlined
                                    class="text-white mb-3"
                                >
                                    Back
                                </v-btn>
                                <v-row>
                                    <v-col cols="12">
                                        <v-subheader></v-subheader>
                                        <v-radio-group
                                            v-model="selectedSubcategory"
                                            @change="handleSubcategoryChange"
                                            column
                                            :rules="[rules.required]"
                                        >
                                            <v-radio
                                                v-for="(subcategory, index) in subcategories"
                                                :key="index"
                                                :label="subcategory.label"
                                                :value="subcategory.value"
                                                color="#05c3a8"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </div>

                            <div v-if="step === 3">
                                <v-btn
                                    @click="goBack"
                                    color="#05c3a8"
                                    outlined
                                    class="text-white mb-3"
                                >
                                    Back
                                </v-btn>
                                <v-row>
                                    <v-col cols="12">
                                        <v-subheader></v-subheader>
                                        <v-radio-group
                                            v-model="selectedFinalOption"
                                            @change="handleFinalOptionChange"
                                            column
                                            :rules="[rules.required]"
                                        >
                                            <v-radio
                                                v-for="(finalOption, index) in finalCategories"
                                                :key="index"
                                                :label="finalOption.label"
                                                :value="finalOption.value"
                                                color="#05c3a8"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="email"
                                            label="Email"
                                            type="email"
                                            required
                                            color="#05c3a8"
                                            outlined
                                            :rules="[rules.required, rules.email]"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </div>

                            <v-row v-if="step === 3" class="mt-4">
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="additionalInfo"
                                        label="Anything else you want to add?"
                                        color="#05c3a8"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn
                                        @click="handleSubmit"
                                        :disabled="!valid"
                                        color="#05c3a8"
                                        class="text-white"
                                    >
                                        Submit
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </div>

                    <div v-else>
                        <v-row justify="center">
                            <v-col cols="12" class="text-center">
                                <v-alert
                                    type="success"
                                    style="background-color: #05c3a8; color: #19191e"
                                >
                                    Thank you for your submission, we will get in touch with you
                                    soon!
                                </v-alert>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios';

export default {
    data() {
        return {
            step: 1,
            selectedCategory: null,
            selectedSubcategory: null,
            selectedFinalOption: null,
            subcategories: [],
            finalCategories: [],
            jsonData: {},
            submitted: false,
            additionalInfo: '',
            email: '',
            valid: false,
            rules: {
                required: (value) => !!value || 'Required.',
                email: (value) => {
                    const pattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
                    return pattern.test(value) || 'Invalid e-mail.';
                },
            },
        };
    },
    methods: {
        handleCategoryChange() {
            this.step = 2;
            // Update subcategories based on selected category
            if (this.selectedCategory === 'Website') {
                this.subcategories = [
                    { label: 'I have a logo', value: 'Logo' },
                    { label: 'I do not have a logo', value: 'NoLogo' },
                ];
            } else if (this.selectedCategory === 'OnlineStore') {
                this.subcategories = [
                    { label: 'I need payment integration', value: 'Payment' },
                    { label: 'I do not need payment integration', value: 'NoPayment' },
                ];
            } else if (this.selectedCategory === 'Portfolio') {
                this.subcategories = [
                    { label: 'I need a gallery', value: 'Gallery' },
                    { label: 'I do not need a gallery', value: 'NoGallery' },
                ];
            }
            this.updateJsonData();
        },
        handleSubcategoryChange() {
            this.step = 3;
            // Update final categories based on selected subcategory
            if (this.selectedSubcategory === 'Logo' || this.selectedSubcategory === 'NoLogo') {
                this.finalCategories = [
                    { label: 'I need Search engine optimization', value: 'SEO' },
                    { label: 'I do not need Search engine optimization', value: 'NoSEO' },
                ];
            } else if (
                this.selectedSubcategory === 'Payment' ||
                this.selectedSubcategory === 'NoPayment'
            ) {
                this.finalCategories = [
                    { label: 'I need inventory management', value: 'Inventory' },
                    { label: 'I do not need inventory management', value: 'NoInventory' },
                ];
            } else if (
                this.selectedSubcategory === 'Gallery' ||
                this.selectedSubcategory === 'NoGallery'
            ) {
                this.finalCategories = [
                    { label: 'I need social media integration', value: 'SocialMedia' },
                    { label: 'I do not need social media integration', value: 'NoSocialMedia' },
                ];
            }
            this.updateJsonData();
        },
        handleFinalOptionChange() {
            this.updateJsonData();
        },
        updateJsonData() {
            this.jsonData = {
                category: this.selectedCategory,
                subcategory: this.selectedSubcategory,
                finalOption: this.selectedFinalOption,
                additionalInfo: this.additionalInfo,
                email: this.email,
            };
        },
        async handleSubmit() {
            if (this.$refs.form.validate()) {
                this.updateJsonData();
                try {
                    const response = await axios.post(
                        'https://api.web3forms.com/submit',
                        {
                            access_key: '54a4e246-2cd0-466b-b699-4406948fe918',
                            ...this.jsonData,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Accept: 'application/json',
                            },
                        },
                    );

                    if (response.data.success) {
                        this.submitted = true;
                        console.log(response.data);
                    }
                } catch (error) {
                    console.error('There was an error submitting the form:', error);
                }
            }
        },
        goBack() {
            if (this.step === 3) {
                this.step = 2;
                this.selectedFinalOption = null;
            } else if (this.step === 2) {
                this.step = 1;
                this.selectedSubcategory = null;
                this.subcategories = [];
                this.finalCategories = [];
            }
        },
    },
};
</script>

<style scoped>
.pricing-container {
    background-color: #fff;
    color: #19191e;
    padding: 20px;
    border-radius: 8px;
    margin-top: 100px;
    margin-bottom: 100px;
    text-align: center;
}

.v-card-title {
    margin-bottom: 30px;
}

.v-subheader {
    margin-bottom: 20px;
}

.v-btn {
    background-color: #05c3a8;
    color: white;
}

@media (max-width: 767px) {
    .pricing-container {
        margin-bottom: 50px;
        margin-top: 50px;
        padding: 10px;
    }
    .v-card-title {
        font-size: 24px;
    }
    .v-subheader {
        font-size: 16px;
    }
    .v-radio-group {
        font-size: 14px;
    }
    .v-btn {
        font-size: 14px;
        width: 100%;
    }
}
</style>
