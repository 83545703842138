<template>
    <div>
        <NavBar />
        <Slogan />
        <Collaboration />
        <Services />
        <Pricing />
        <Contact />
        <Footer />
    </div>
</template>

<script>
import NavBar from '../components/NavBar.vue';
import Slogan from '../components/Slogan.vue';
import Services from '../components/Services.vue';
import Collaboration from '../components/Collaboration.vue';
import Pricing from '../components/Pricing.vue';
import Contact from '../components/Contact.vue';
import Footer from '../components/Footer.vue';
export default {
    name: 'HomeView',
    components: {
        NavBar,
        Slogan,
        Services,
        Collaboration,
        Pricing,
        Contact,
        Footer,
    },
};
</script>

<style></style>
