<template>
    <div class="trusted-companies">
        <p>Strengthen your online store with plugins for shipping, payment and much more!</p>
        <div class="company-logos logos-1">
            <img
                v-for="(company, index) in companies1"
                :key="index"
                :src="company.logo"
                :alt="company.name"
            />
        </div>
        <div class="company-logos logos-2">
            <img
                v-for="(company, index) in companies2"
                :key="index"
                :src="company.logo"
                :alt="company.name"
            />
        </div>
    </div>
</template>

<script>
import paypal from '../assets/paypal.png';
import dhl from '../assets/dhl.png';
import bosta from '../assets/bosta.png';
import aramex from '../assets/aramex.png';
import instapay from '../assets/instapay.png';
import dpd from '../assets/dpd.png';

export default {
    name: 'Collaboration',
    data() {
        return {
            companies1: [
                { name: 'Paypal', logo: paypal },
                { name: 'DPD', logo: dpd },
                { name: 'Bosta', logo: bosta },
                { name: 'DHL', logo: dhl },
                { name: 'Aramex', logo: aramex },
                { name: 'Instapay', logo: instapay },
            ],
            companies2: [],
        };
    },
};
</script>

<style scoped>
.trusted-companies {
    text-align: center;
    margin: 70px 0;
}

.trusted-companies p {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.company-logos {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.company-logos img {
    height: 40px;
    max-width: 170px;
    filter: grayscale(100%);
    transition: filter 0.3s;
}

.company-logos img:hover {
    filter: grayscale(0%);
}

@media (max-width: 767px) {
    .trusted-companies {
        margin: 40px 0;
    }
    .trusted-companies p {
        font-size: 14px;
        margin-bottom: 15px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .company-logos img {
        height: 30px;
        max-width: 120px;
    }
}
</style>
