import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import Lottie from 'lottie-vuejs';
import vue3lottie from 'vue3-lottie';
import { loadFonts } from './plugins/webfontloader';

loadFonts();

createApp(App).use(vuetify).use(vue3lottie).mount('#app');
