<template>
    <v-container class="slogan-container">
        <v-row>
            <v-col cols="12" lg="1" class="text-center"></v-col>
            <v-col cols="12" lg="5" class="text-center d-flex flex-column justify-center">
                <h1 class="display-1 text-left custom-font-size-1">
                    Welcome to inside out, elevate your
                    <span>business</span>
                    by creating a world class
                    <span>online store</span>
                </h1>
                <p color="#19191e" class="subheading text-left">
                    We will design and create your online store, providing a seamless shopping
                    experience for your customers. Our service includes an intuitive admin
                    dashboard, allowing you to effortlessly manage your content.
                </p>
            </v-col>
            <v-col cols="12" lg="5" class="text-center d-flex flex-column justify-start">
                <Vue3Lottie :animationData="animation1" class="animation" />
            </v-col>
            <v-col cols="12" lg="1" class="text-center"></v-col>
        </v-row>
    </v-container>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie';

import * as animation from '../assets/animation1.json';
export default {
    name: 'Slogan',
    components: {
        Vue3Lottie,
    },
    data() {
        return {
            animation1: animation,
        };
    },
};
</script>

<style scoped>
h1 {
    color: #19191e;
    font-family: 'calibri';
}
span {
    color: #05c3a8;
}
.custom-font-size-1 {
    font-size: 70px;
}

.animation {
    height: 650px;
    width: 650px;
}

@media (max-width: 767px) {
    .custom-font-size-1 {
        font-size: 40px;
    }
    .animation {
        height: 300px;
        width: 300px;
    }
    .subheading {
        font-size: 16px;
    }
}
</style>
