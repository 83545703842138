<template>
    <v-footer padless>
        <v-col class="text-center py-3" style="background-color: #19191e; color: white">
            <v-container>
                <v-row class="mb-2">
                    <v-col class="text-center" cols="12">
                        <h3>You build the business, we take it to the world.</h3>
                    </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row class="mt-2">
                    <v-col class="text-center" cols="12">
                        <p>&copy; 2024 Inside out. All rights reserved.</p>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
    </v-footer>
</template>

<script>
export default {
    name: 'Footer',
};
</script>

<style scoped>
.v-footer {
    background-color: #19191e;
    color: white;
}
</style>
