<template>
    <v-container id="services" class="pa-5 services-container text-center">
        <h1 class="text-h4">Our Services</h1>
        <v-row class="d-flex flex-column">
            <v-row class="d-flex justify-center">
                <v-col cols="12" lg="4" class="d-flex flex-column align-center pa-7">
                    <Vue3Lottie :animationData="animation5" class="animation" />
                    <h3>Design & Development</h3>
                    <p>
                        We will design, develop, and deploy your online store, providing a seamless
                        shopping experience for your customers.
                    </p>
                </v-col>
                <v-col cols="12" lg="4" class="d-flex flex-column align-center pa-7">
                    <Vue3Lottie :animationData="animation3" class="animation" />
                    <h3>Content Management</h3>
                    <p>
                        We will provide you with an intuitive admin dashboard, allowing you to
                        effortlessly manage your content.
                    </p>
                </v-col>
                <v-col cols="12" lg="4" class="d-flex flex-column align-center pa-7">
                    <Vue3Lottie :animationData="animation4" class="animation" />
                    <h3>Search Engine Optimization</h3>
                    <p>
                        We provide comprehensive SEO services for your online store, ensuring higher
                        visibility and more traffic.
                    </p>
                </v-col>
            </v-row>
        </v-row>
    </v-container>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie';

import * as animation2 from '../assets/animation2.json';
import * as animation3 from '../assets/animation3.json';
import * as animation4 from '../assets/animation4.json';
import * as animation5 from '../assets/animation5.json';

export default {
    name: 'Services',
    components: {
        Vue3Lottie,
    },
    data() {
        return {
            animation2: animation2,
            animation3: animation3,
            animation4: animation4,
            animation5: animation5,
        };
    },
};
</script>

<style scoped>
.services-container {
    margin-top: 150px;
    margin-bottom: 150px;
}

h1 {
    font-size: 24px;
    margin-bottom: 30px;
}

h3 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

p {
    font-size: 16px;
    color: #666;
}

.animation {
    height: 350px;
    width: 350px;
}

@media (max-width: 767px) {
    .services-container {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    h1 {
        font-size: 20px;
        margin-bottom: 20px;
    }
    h3 {
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 8px;
    }
    p {
        font-size: 14px;
    }
    .animation {
        height: 200px;
        width: 200px;
    }
}
</style>
