<template>
    <v-app>
        <v-main>
            <HomeView />
        </v-main>
    </v-app>
</template>

<script>
import HomeView from './views/HomeView.vue';

export default {
    name: 'App',

    components: {
        HomeView,
    },

    data: () => ({
        title: 'insideout',
    }),

    watch: {
        title(newTitle) {
            document.title = newTitle;
        },
    },
    mounted() {
        this.title = 'Insideout'; // Set initial title
    },
    methods: {
        updateTitle(newTitle) {
            this.title = newTitle; // Call this method to change title dynamically
        },
    },
};
</script>
<style>
#app {
    width: 100vw;
}
html,
body {
    overflow-x: hidden;
}
</style>
