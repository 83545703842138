<template>
    <v-container id="navbar">
        <v-app-bar :elevation="0" app>
            <v-container class="d-flex justify-center align-center">
                <v-img
                    src="../assets/inside-removebg-preview.png"
                    alt="Logo"
                    height="40"
                    max-width="100"
                    class="mr-4 logo-pointer"
                    @click="scrollTo('navbar')"
                ></v-img>
                <div class="d-none d-md-flex">
                    <v-btn plain color="#19191e" @click="scrollTo('services')">Our Services</v-btn>
                    <v-btn plain color="#19191e" @click="scrollTo('pricing')">Pricing</v-btn>
                    <v-btn plain color="#19191e" @click="scrollTo('contact')">Contact</v-btn>
                </div>

                <!-- Mobile view -->
                <v-app-bar-nav-icon
                    class="d-md-none"
                    @click="drawer = !drawer"
                ></v-app-bar-nav-icon>
            </v-container>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" temporary class="d-md-none">
            <v-list dense>
                <v-list-item @click="scrollTo('services')">
                    <v-list-item-title>Our Services</v-list-item-title>
                </v-list-item>
                <v-list-item @click="scrollTo('pricing')">
                    <v-list-item-title>Pricing</v-list-item-title>
                </v-list-item>
                <v-list-item @click="scrollTo('contact')">
                    <v-list-item-title>Contact</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </v-container>
</template>

<script>
export default {
    name: 'NavBar',
    data() {
        return {
            drawer: false,
        };
    },
    methods: {
        scrollTo(section) {
            const element = document.getElementById(section);
            if (element) {
                const offset = -100;
                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition + offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });

                this.drawer = false; // Close the drawer on mobile after click
            }
        },
    },
};
</script>

<style scoped>
.v-btn {
    position: relative;
    overflow: hidden;
}

.v-btn::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #19191e;
    transition: all 0.3s ease;
}

.v-btn:hover::after {
    left: 0;
    width: 100%;
}

.v-btn:hover {
    color: #05c3a8 !important;
}

.v-app-bar-nav-icon {
    margin-left: auto;
}

.logo-pointer {
    cursor: pointer;
}

@media (max-width: 767px) {
    .v-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
</style>
